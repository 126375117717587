import * as React from "react";
import { css } from "@emotion/css";
import clsx from "clsx";
import { ImgCaption, Button, Page, Section, Blog } from "../components";
import { SEO } from "../components/SEO";

import {
  GreyBlock,
  contentStyle,
  h1Style,
  p3Style,
  twoColBlock,
  p1Style,
  demoStyle,
  h3Style,
  blockSeparator,
  figcaption,
} from "../globalstyle";

import img01 from "../images/tom-claes-cj73y17e63e-unsplash.jpg";
import img02 from "../images/scott-graham-5fnmwej4taa-unsplash.jpg";
import img03 from "../images/soutiens.png";
import EmbedVideo from "../components/EmbedVideo";

// markup
const IndexPage = () => {
  return (
    <Page tabTitle="Accueil">
      <SEO />
      <div className={GreyBlock}>
        <div className={contentStyle}>
          <h1
            className={clsx(
              h1Style,
              css`
                text-align: none;
              `
            )}
          >
            Dotter.science, la solution performante et intuitive de gestion de
            données pour la recherche clinique
          </h1>
          <p
            className={clsx(
              p3Style,
              css`
                font-size: 24px;
              `
            )}
          >
            Destinée aux chercheurs et aux organismes menant des protocoles de
            recherche en santé, notre plateforme <b>EDC</b> permet la collecte
            et le management des données en toute sécurité. Concevez et
            complétez vos formulaires (<b>eCRF</b>) en toute simplicité et
            reprenez la main sur vos études cliniques !
          </p>
          <div
            className={css`
              display: flex;
              height: max-content;
              justify-content: center;
              align-items: flex-start;
            `}
          >
            <div className={twoColBlock}>
              <ImgCaption
                className={css`
                  max-width: 430px;
                  width: 25vw;
                `}
                src={img01}
                title="Vous avez un projet académique ou associatif ?"
                titleformat={p1Style}
                descformat={css`
                  display: flex;
                  justify-content: center;
                `}
              >
                <p className={p3Style}>
                  Profitez de nos prix réduits <br />
                  &nbsp;
                </p>

                <Button color="blue" link="/landing-1" noFollow>
                  JE CLIQUE ICI
                </Button>
              </ImgCaption>
              <ImgCaption
                className={css`
                  width: 25vw;
                `}
                src={img02}
                title="Vous avez un projet de recherche industrielle ?"
                titleformat={p1Style}
                descformat={css`
                  display: flex;
                  justify-content: center;
                `}
              >
                <p className={p3Style}>
                  Entreprise, laboratoire, CRO, profitez de notre service client
                  haut de gamme !
                </p>

                <Button link="/landing-2" color="blue" noFollow>
                  JE CLIQUE ICI
                </Button>
              </ImgCaption>
            </div>
          </div>
        </div>
      </div>

      <div className={demoStyle}>
        <EmbedVideo width="780px" height="439px" />
        <h2 className={h3Style}>
          Vous souhaitez accéder directement à une démo en ligne ?
        </h2>
        <Button link="/contact/#formulaire-de-contact" color="green">
          ESSAYEZ NOTRE SOLUTION !
        </Button>
      </div>
      <Section color="green" title="Actualités">
        <Blog />
      </Section>
      <hr className={blockSeparator} />
      <img
        src={img03}
        alt="Nos soutiens"
        className={css`
          width: 90vw;
          max-width: 1000px;
        `}
      />
      <div className={figcaption}>
        Ils soutiennent notre projet, merci à eux !
      </div>
    </Page>
  );
};

export default IndexPage;
