import React from "react";

export function SEO() {
  const schema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    name: "Dotter.science",
    url: "http://dotter.science",
    image:
      "https://www.dotter.science/static/patient_dotter-36d22cf352d4ef0b8dd0233de0588321.png",
    description:
      "Dotter.science est une plateforme en ligne permettant d’optimiser la conception, la saisie (eCRF) et le management électronique des protocoles de recherche clinique (EDC).",
    brand: "Dotter.science",
    offers: {
      "@type": "Offer",
      url: "https://www.dotter.science/tarifs/",
      priceCurrency: "EUR",
      price: "2500",
      availability: "https://schema.org/OnlineOnly"
    },
    sameAs: [
      "https://www.facebook.com/dotter.science/",
      "https://twitter.com/Dotter_science",
      "https://www.linkedin.com/company/dotter-science/"
    ]
  };

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
    />
  );
}
